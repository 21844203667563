<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>iLe Ayudamos A Obtener </h1>
          <h1>Un Préstamo Más Fácilmente! </h1>
          <h4>Sin necesidad de documentos hipotecarios, Solicita tu crédito 100% online.</h4>
          <h4>En 3 Pasos Sencillos, Obtenga Su Dinero En Un Plazo De 1 A 24 Horas.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <h3>¿Cuál es nuestra ventaja?</h3>
      <p>El proceso es simple y seguro, y puede solicitar un préstamo en cualquier momento y en cualquier lugar.</p>
      <div class="inner2_item flex fw-w jc-sb">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <!-- <img class="bg" :src="item.img" alt=""> -->
          <img class="bg2" :src="item.img2" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner3">
      <h3>Préstamo rápido en tres pasos</h3>
      <p>La forma más fácil de solicitar un préstamo en línea, ¡más confiable que tus amigos!</p>
      <div class="inner2_item flex fw-w jc-sb">
        <div v-for="(item,index) in leftItemList2" :key="index" class="content">
          <!-- <img class="bg" :src="item.img" alt=""> -->
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
          <img class="bg2" :src="item.img2" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <h3>Comentarios de los usuarios</h3>
      <p>Tenemos 4.7 de 5 estrellas en Google Play y más de 100 k de reseñas de 5 estrellas en todo el mundo.</p>
      <!-- <p><img src="@/assets/images/loanImg/star2.png">4.5</p> -->
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c">
                <img class="tx" :src="item.img">
                <div>
                  <h1>{{ item.name }}</h1>
                  <h2>{{ item.txt }}</h2>
                  <h3>{{ item.name2 }}</h3>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <!-- <div class="flex jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div> -->
    </div>
    <div id="selector3" class="inner inner5">
      <!-- <h3>Ventajas de nuestros productos</h3> -->
      <!-- <p>Préstamos de crédito en efectivo.Resuelva rápido emergencia personal por celular.</p> -->
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_5items" :key="index" class="content flex" :class="index %2 === 0 ?'fd-r jc-sb cloor2':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <h1>{{ item.title }}</h1>
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <p v-if="item2.txt">{{ item2.txt }}</p>
              <h2 v-if="item2.txt2">{{ item2.txt2 }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <!-- <div class="title">
        <h3>Contáctanos</h3>
        <p>Cualquier dudas o inconveniencias que ocurran durante proceso de solicitar préstamo, estamos a sus ordenes por correo.</p>
      </div> -->
      <div class="flex items jc-sb">
        <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="content flex jc-sb ai-c">
          <div>
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 }}</p>
          </div>
          <img :src="item.img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Cantidad alta', txt2: 'Solicitud 100% en línea, el monto \n máximo hasta de $20,000', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Intereses bajos', txt2: 'Interés de préstamo diario tan \n bajo como 0.01%', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Llegada rápida', txt2: 'Llegue rápidamente a su cuenta \n bancaria en 3 minutos', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') },
        { txt: 'Operación simple', txt2: 'Préstamo fácil para teléfonos móviles, \n sencillo y rápido', img: require('@/assets/images/itemList_04.png'), img2: require('@/assets/images/itemList_07.png') }
      ],
      leftItemList2: [
        { txt: 'Datos enviados', txt2: 'Descargue la aplicación FondosMíos, cargue la \n tarjeta de identificación y complete la información \n personal .', img: require('@/assets/images/itemList_08.png'), img2: require('@/assets/images/itemList_08.png') },
        { txt: 'Revisión aprobada', txt2: 'Seleccione el monto de su préstamo y el tiempo \n de pago, envíe la solicitud y espere la revisión.', img: require('@/assets/images/itemList_09.png'), img2: require('@/assets/images/itemList_09.png') },
        { txt: 'Obtención de préstamos', txt2: 'Descargue la aplicación FondosMíos, cargue la \n tarjeta de identificación y complete la información \n personal .', img: require('@/assets/images/itemList_10.png'), img2: require('@/assets/images/itemList_10.png') }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the FondosMíos app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Timo Caballero', name2: '¡Fue increíble!', txt: 'Profesor', txt2: 'La línea es alta y el préstamo llegó rápidamente. \n El interés es muy bajo. El goteo muy bueno \n resolvió mis problemas financieros.', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Armando Veras', name2: 'Seguro y rápido', txt: 'Contable', txt2: 'Acabo de solicitar con éxito un préstamo \n de $ 20.000, solo me tomó unos minutos \n completar la información, el préstamo fue \n rápido, no lo podía creer.', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Roman Arguello', name2: '¡Muchas gracias!', txt: 'Conductor', txt2: 'Soy un cliente habitual de esta aplicación. \n He solicitado un préstamo tres veces y todo \n ha ido bien. Es realmente conveniente tenerlo \n en la vida. ¡Muchas gracias!', img: require('@/assets/images/Product03.png') },
        { id: '4', name: 'Timo Caballero', name2: 'Gran producto', txt: 'Líder de línea', txt2: 'La línea es alta y el préstamo llegó rápidamente. \n El interés es muy bajo. El goteo muy bueno \n resolvió mis problemas financieros.', img: require('@/assets/images/Product03.png') }
      ],
      visibleItemCount: 4,
      currentIndex: 0,
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Vaya a GooglePlay para descargar \n FondosMíos ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Debe registrarse y completar la \n información requerida para terminar \n la verificación.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Solicitar préstamos', txt2: 'Puede elegir el monto de préstamos \n de acuerdo con sus necesidades y \n agregar una cuenta para recibir el \n dinero.', img: require('@/assets/images/icon-3.png') },
        { id: '4', name: 'Obtención de \n préstamos', txt: 'Obtención de \n préstamos', txt2: 'Una vez aprobado el sistema, el dinero \n se transferirá directamente a su tarjeta \n bancaria.', img: require('@/assets/images/icon-4.png') },
        { id: '5', name: 'Alhertina Rayon', txt: 'Pagar el préstamo a \n tiempo', txt2: 'Para aumentar el límite de crédito y \n evitar multas, debe pagar a tiempo \n los préstamos antes de las fechas \n de vencimiento.', img: require('@/assets/images/icon-5.png') },
        { id: '6', name: 'Alhertina Rayon', txt: 'Volver a pedier \n préstamos', txt2: 'Nos complace ayudarlo a alcanzar \n la vida de su sueño y esperamos \n poder brindarle ayuda nuevamente.', img: require('@/assets/images/icon-5.png') }
      ],
      inner_5items: [
        { img: require('@/assets/images/inner_5_1.png'), title: 'Sobre nosotros ', list2: [{ txt: 'FondosMíos es una aplicación local de préstamos rápidos en México. \n Puede solicitar un préstamo personal en línea a través de nuestro \n servicio de crédito personal sin necesidad de aportar una garantía. \n FondosMíos ofrece préstamos personales en efectivo a los tipos de \n interés más bajos para todos. Cualquiera que sea su emergencia, cuando \n y donde necesite ayuda en efectivo, descargue la aplicación de préstamo \n en efectivo. Ofrece un servicio de préstamos personales en línea seguro \n donde su información está protegida al más alto nivel.' }, { txt: '' }, { txt: '' }] }
      ],
      inner6ItemLeftList: [
        { img: require('@/assets/images/loanImg/email.png'), txt: 'E-mail:', txt2: 'fondosmiosapp@gmail.com' },
        { img: require('@/assets/images/loanImg/address.png'), txt: 'Dirección de la oficina:', txt2: 'Juan Salvador Agraz 69, Santa Fe, \n Alcaldía, Cuajimalpa de Morelos, \n 05348 Ciudad de México, CDMX' },
        { img: require('@/assets/images/loanImg/time.png'), txt: 'Tiempo laboral：', txt2: 'Lunes-Domingo 9:30am-6:30pm' }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=fondosmios.com.credito', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:60px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat ;
    background-size: 100% 100%;
    // background-position: 85% 50%;
    height: 450px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      color: #fff;
      h1{
        font-size: 38px;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 70px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 10px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        height: 170px;
        .info_img1{
          width: 18px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 40px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 360px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:80px calc((100% - 1100px) / 2);
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #FFFFFF;
    h3{
      font-size: 30px;
      margin-bottom: 40px;
      text-align: center;
    }
    p{
      color: #666666;
      font-size: 14px;
      margin-bottom: 60px;
    }
    text-align: center;
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      background: #3C50FB;
      border-radius: 20px;
      width: 23%;
      color:white;
      padding: 20px 0;
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 25%;
        margin: 0px auto 0;
      }
      p{
        font-size: 12px;
        line-height: 20px;
        margin: 0 auto;
        color: white;
      }
      .item_info{
        h1{
          font-size: 18px;
          line-height: 50px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .inner3{
    position: relative;
    padding:80px calc((100% - 1100px) / 2);
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #F3F3F3;
    h3{
      font-size: 30px;
      margin-bottom: 40px;
      text-align: center;
    }
    p{
      color: #666666;
      font-size: 14px;
      margin-bottom: 60px;
    }
    text-align: center;
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      background: url("~@/assets/images/loanImg/inner3_bg.png") #FFFFFF no-repeat bottom;
      background-size: 85% auto;
      background-position-x: 25px;
      border-radius: 10px;
      width: 30%;
      padding: 35px 0 25px;
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: 0px auto 0;
      }
      p{
        font-size: 12px;
        line-height: 20px;
        margin: 25px auto 40px;
        width: 87%;
        text-align: left;
        height: 60px !important;
      }
      .item_info{
        h1{
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
  .inner4{
    background: #FFF;
    padding: 50px calc((100% - 1250px) / 2) 60px;
    h3{
      font-size: 30px;
      text-align: center;
      // margin-bottom: 20px;
      position: relative;
      margin-bottom: 30px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      font-size: 14px;
      color: #666666;
      text-align: center;
      line-height: 28px;
      margin-bottom: 40px;
      img{
        width: 180px;
        height: 25px;
        vertical-align:top !important;
        margin-right: 15px;
      }
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 350px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
      padding: 20px 0 0 0;
    }
    .carousel-item {
      flex: 1;
      height: 280px;
      display: flex;
      align-items: center;
      background:#F3F3F3;
      border-radius: 10px;
      font-size: 18px;
      padding: 0px 8px 10px;
      margin: 0 8px;
      .stars{
        img{
          width: 14px;
          height: 14px;
        }
      }
      h1{
        color: black;
        font-size: 15px;
        line-height: 18px;
        text-align:  center;
        font-weight: bold;
        margin-top: 25px;
      }
      h2{
        color: #999999;
        font-size: 12px;
        line-height: 30px;
        text-align:  center;
        font-weight: 500;
      }
      h3{
        color: black;
        font-size: 15px;
        line-height: 18px;
        text-align:  center;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      img{
        width: 70px;
        height: 70px;
        margin-top: -30px;
      }
      p{
        color: #666666;
        height: 100px;
        font-size: 12px;
        text-align: left;
        font-weight: normal;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      margin: 10px 5px;
      cursor: pointer;
      img{
        width: 30px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
  .inner5{
    position: relative;
    padding:40px calc((100% - 1250px) / 2);
      // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #2736B7;
    h3{
      font-size: 30px;
      color: black;
      margin-bottom: 30px;
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 16px;
      margin-bottom: 70px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 100%;
      text-align: left;
      color:white;
      background: #FFFFFF;
      h1{
        font-size: 28px;
        margin-bottom: 30px;
        line-height: 34px;
        // margin:0 0 50px 0;
        position: relative;
      }
      p{
        text-align: left;
        font-size: 15px;
        line-height: 25px;
        font-weight: bold;
        // margin-left: 28px;
        position: relative;
        margin-bottom: 10px;
        // &::before{
        //   position: absolute;
        //   display: block;
        //   content:'';
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 5px;
        //   background: #0071D6;
        //   margin:0 10px 5px 0;
        //   left: -28px;
        //   top: 7px;
        // }
      }
      h2{
        color: #666666;
        text-align: left;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 100 !important;
        line-height: 23px;
      }
      img{
        width: 500px;
        // margin-right: 50px;
      }
    }
    .cloor2{
        background: transparent !important;
        h1,p{
          color: white !important;
        }
      }
    .content2{
      width: 42%;
      text-align: left;
      color: black;
      margin-left: 50px;
      justify-content: center;
      p{
        color: #666666;
        font-weight: 100 !important;
        line-height: 23px;
      }
    }
  }
  .inner6{
    position: relative;
    padding:100px calc((100% - 1150px) / 2) ;
    background: #F3F3F3;
    white-space: pre-line;
    .title{
      margin-bottom: 50px;
      text-align: center;
      padding-left: 10px;
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 30px;
        color: #333333;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 33%;
      margin: 0 10px;
      padding: 12px 40px 20px;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0px 3px 20px 1px rgba(173,173,173,0.16);
      div{
        // height: 100%;
      }
      img{
        width: 30px;
      }
      h1{
        font-size: 16px;
        line-height: 50px;
      }
      p{
        color:#666666;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 350px;
      position: relative;
      background:white;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 25px;
          line-height: 35px;
          // line-height: 50px;
          color: black;
          padding-left: 30px;
        }
        h4{
          padding: 0 30px !important;
          font-size: 12px;
          margin: 0 !important;
          color: black;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 20px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding: 50px calc((100% - 1200px) / 2) 0px;
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      p{
        margin-bottom:20px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        margin-bottom: 20px;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        p{
          margin-bottom: 10px;
          width: 85%;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner3{
      padding: 50px calc((100% - 1200px) / 2) 0px;
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      p{
        margin-bottom:20px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        margin-bottom: 20px;
        // padding: 20px 0;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        p{
          margin-bottom: 10px;
          width: 85%;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner4{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      display: none;
      padding: 50px 0px 0;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
        margin-bottom: 10px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        flex-direction: column;
        margin-bottom: 00px;
        h1{
          font-size: 20px;
          margin: 20px 0;
        }
        img{
          width:100% !important;
          margin: 0 auto;
        }
        p{
          width: 80% !important;
          margin: 0 auto;
          font-size: 12px;
        }
      }
      .content2{
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        padding: 0 !important;
        margin-left: 0;
        h2{
          margin: 0 20px;
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 600px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }

    .inner6{
      padding: 50px calc((100% - 1300px) / 2);
      .items{
        flex-direction: column;
        margin: 0 auto;
        .content{
          width: 85vw;
          margin: 0 auto 20px;
        }
      }
    }
  }
}
</style>
